import axios from "axios";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:3001",
  baseURL: "https://aitapi.evluate.com/",
  
  // baseURL: "https://puzn02pon1.execute-api.ap-south-1.amazonaws.com/dev/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;

    if (response && response.status === 401) {
      // Clear all data from local storage
      localStorage.clear();

      // Redirect to landing page
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
